<script setup>
import {useBaseStore} from "~/stores/base";
import ModuleLoginEntrance from "~/components/ModuleLoginEntrance.vue";

/** computed **/
const isMobile = computed(() => useBaseStore().getterIsMobile)
</script>

<template>
  <div class="module-guest-mobile" v-if="isMobile">
    <module-login-entrance/>
  </div>
  <div class="module-guest-desktop" v-else>
    <module-login-entrance/>
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/config";

.module-guest-mobile {
}

.module-guest-desktop {
}
</style>
