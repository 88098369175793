<script setup>
import {useBaseStore} from "~/stores/base";
import Drawer from "~/components/Drawer.vue";
import MyWebSocket from "@/utils/websocket"
import MessageBubble from "~/components/DrawerAI/MessageBubble.vue";
import {aiHistoryList} from "~/api/api.ai";
import ScrollList from "~/components/DrawerAI/ScrollList.vue";
import {CARD_TYPE, ROLE, TALK_STATUS} from "~/utils/constants";
import {Loading} from "@element-plus/icons-vue";
import {v4 as uuidv4} from 'uuid';
import {PAGE_LABEL, smartLookTrackPageView} from "~/utils/smartlook";

const route = useRoute()
const router = useRouter()

const open = ref(false)
let ws;
const dialogBox = ref(null);
const inputRef = ref(null)
const isShowBtnScrollToBottom = ref(false)
const histories = ref([])
const isFetchingHistories = ref(false)
const messages = ref([])
const messageQueue = ref([])
const text = ref("")
const pageIndex = ref("")
const pageSize = ref(10)
const tipSections = ref([])
const talkStatus = ref("")

/** computed **/
const isMobile = computed(() => useBaseStore().getterIsMobile)
const clearStatus = computed(() => useBaseStore().getterDrawerAiClearStatus)
const hash = computed(() => route.hash)

/** watch **/
watch(hash, async () => {
  if (hash.value === "#ai") {
    open.value = true
    reset({resetWS: true})
    await getHistory(pageIndex.value)
    scrollToBottomSmooth()
    await init()
    inputRef.value.focus()
    if (dialogBox.value) {
      // 添加滚动事件监听器
      dialogBox.value.addEventListener('scroll', handleScroll);
    }
    useBaseStore().updatePageLabel(PAGE_LABEL.AI)
    smartLookTrackPageView()
  } else {
    open.value = false
    destroy()
    if (dialogBox.value) {
      dialogBox.value.removeEventListener('scroll', handleScroll);
    }
  }
})

watch(clearStatus, async () => {
  if (clearStatus.value === 2) {
    reset({resetWS: false})
    talkStatus.value = TALK_STATUS.LEISURE
  }
})

/** methods **/
/**
 * @typedef {Object} Options
 * @property {boolean} resetWS - 是否重置ws
 */
/**
 * 消息发送
 * @param {Options} options - 选项
 */
const reset = (options) => {
  if (options.resetWS) ws = null
  isShowBtnScrollToBottom.value = false
  histories.value = []
  isFetchingHistories.value = false
  messages.value = []
  messageQueue.value = []
  text.value = ""
  pageIndex.value = ""
  pageSize.value = 10
  tipSections.value = []
  talkStatus.value = ""
}
const renderTipSections = (newTips) => {
  if (newTips && newTips.length) tipSections.value = newTips
}
/** 关闭Drawer **/
const handleCloseAI = () => {
  // 获取当前路径，但去掉 hash 部分
  router.replace({
    path: router.currentRoute.value.path,
    query: router.currentRoute.value.query, // 保留查询参数
    hash: '' // 移除 hash
  });
}
/** 初始化 **/
const init = () => {
  return new Promise(resolve => {
    ws = new MyWebSocket()
    ws.connect({
      // 已建联
      onopen: () => {
        talkStatus.value = TALK_STATUS.LEISURE
        // 从无历史
        if (histories.value.length === 0) {
          text.value = 'hi'
          handleMessageSend({hidden: true})
        }
        resolve()
      },
      // 收信
      onmessage: (data) => {
        // 结构化消息
        data = JSON.parse(data)
        if (talkStatus.value === TALK_STATUS.AI_TALKING) {
          // 前一个回复尚未结束
          messageQueue.value.push(data.result)
        } else {
          // 当前AI没有正在说话
          messages.value.push(data.result)
          renderTipSections(data.result.tipSections)
          talkStatus.value = TALK_STATUS.AI_TALKING
        }
      },
      // 断联
      onclose: () => {
        const last_message = messages.value[messages.value.length - 1]
        if (last_message && (last_message.role !== ROLE.ASSISTANT || last_message.cardType !== CARD_TYPE.SYSTEM)) {
          // 意外断联
          talkStatus.value = TALK_STATUS.ERROR
        }
      }
    })
  })
}
/** 销毁 **/
const destroy = () => {
  if (ws) ws.disconnect()
}
/**
 * @typedef {Object} Options
 * @property {boolean} hidden - 是否不显示出来
 */
/**
 * 消息发送
 * @param {Options} options - 选项
 */
const handleMessageSend = (options = {}) => {
  // 空字符串，不发送
  if (!text.value) return
  // 非空闲，不发送
  if (talkStatus.value !== TALK_STATUS.LEISURE) return
  // 发送
  ws.send(text.value)
  if (!options.hidden) {
    // push对话框
    messages.value.push({
      role: ROLE.USER,
      cardType: CARD_TYPE.TEXT,
      content: text.value,
      noAnimate: true
    })
  }
  talkStatus.value = TALK_STATUS.WAITING
  // 清理输入框
  text.value = ""
}
/** 获取历史 **/
const getHistory = async (index) => {
  if (isFetchingHistories.value) return
  isFetchingHistories.value = true
  const res = await aiHistoryList({pageIndex: index, pageSize: pageSize.value})
  const newHistories = res.result.chatList.reduce((prev, cur) => {
    const item = {
      id: uuidv4(),
      ...cur,
      noAnimate: true
    };
    prev.push(item)
    renderTipSections(item.tipSections);
    return prev; // 将当前项插入到数组开头
  }, []);
  histories.value = newHistories.concat(histories.value); // 将新消息添加到 histories 开头
  pageIndex.value = res.result.pageIndex
  isFetchingHistories.value = false
}
/** 逐字动画结束 **/
const handleMessageRendered = () => {
  console.log("rendered")
  // 标记该信息为已执行完动画
  messages.value[messages.value.length - 1].noAnimate = true

  if (messageQueue.value.length) {
    // 等待队列中有数据
    messages.value.push(messageQueue.value[0])
    renderTipSections(messageQueue.value[0].tipSections)
    talkStatus.value = TALK_STATUS.AI_TALKING
    // 等待队列删除一个排头
    messageQueue.value.shift()
  } else {
    // 等待队列中无数据
    jumpToBottom()
    const current_message = messages.value[messages.value.length - 1]
    if (current_message.role === ROLE.ASSISTANT) {
      // 助手
      if (current_message.cardType === CARD_TYPE.SYSTEM) {
        // 系统消息，则进入空闲
        talkStatus.value = TALK_STATUS.LEISURE
      } else {
        // 非系统消息，等下一条
        talkStatus.value = TALK_STATUS.WAITING
      }
    }
  }
}
/** 滚动至底部 **/
function jumpToBottom() {
  if (dialogBox.value) {
    dialogBox.value.scrollTo({
      top: dialogBox.value.scrollHeight,
      behavior: "instant" // 立即滚动，而非平滑滚动
    });
  }
}
function scrollToBottomSmooth() {
  dialogBox.value.scrollTo({
    top: dialogBox.value.scrollHeight,
    behavior: "smooth",
  })
}
/** 重试 **/
const handleRetry = async () => {
  talkStatus.value = TALK_STATUS.WAITING
  await init()
  const last_message = messages.value[messages.value.length - 1]
  text.value = last_message.content
  handleMessageSend({hidden: true})
}
/** 点选提醒 **/
const handleTipSelect = (tip) => {
  text.value = tip
  handleMessageSend()
}
/** 滚动 **/
const handleScroll = (e) => {
  if (e.target.scrollTop < 200) handleReachTop(e.target.scrollTop)
  // 露出回到底部按钮：如果 scrollTop 小于 scrollHeight - clientHeight - 200px，说明已经向上滚动了 200px 以上。
  isShowBtnScrollToBottom.value = e.target.scrollTop < e.target.scrollHeight - e.target.clientHeight - 300
}
/** 监听滚动至顶部 **/
const handleReachTop = async (offset) => {
  if (!pageIndex.value) return
  const currentScrollHeight = dialogBox.value.scrollHeight; // 记录当前的scrollHeight
  const currentScrollTop = dialogBox.value.scrollTop;       // 记录当前的scrollTop
  await getHistory(pageIndex.value)
  await nextTick(); // 等待DOM更新
  // 保持滚动位置不变
  // 加载后向上滚动50px，告知用户上方还有数据
  const preScroll = 50
  dialogBox.value.scrollTop = dialogBox.value.scrollHeight - (currentScrollHeight - currentScrollTop + offset) - preScroll;
}
const handleMoreClick = () => {
  useBaseStore().updateIsShowDrawerAIMore(true)
}
const scrollToTop = () => {
  window.scrollTo(0, 0); // 页面滚动到 x:0, y:0
}

/** lifecycle **/
onMounted(async () => {
  if (hash.value === "#ai") {
    open.value = true
    reset({resetWS: true})
    await getHistory(pageIndex.value)
    scrollToBottomSmooth()
    await init()
    inputRef.value.focus()
    if (dialogBox.value) {
      // 添加滚动事件监听器
      dialogBox.value.addEventListener('scroll', handleScroll);
    }
  }
})
</script>

<template>
  <div class="drawer-ai-mobile" v-if="isMobile">
    <Drawer :open="open" :position="'bottom'" :border-radius="'20px 20px 0px 0px'" :z-index="4" @close="handleCloseAI">
      <div class="drawer">
        <div class="drawer-header">
          <img class="close" src="@/assets/img/icon-close-black.svg" alt="icon" @click="handleCloseAI">
          <div class="header-title">
            <img src="@/assets/img/icon-ai.svg" alt="icon">
            bnto ai
            <span class="beta">beta</span>
          </div>
          <img class="more" src="@/assets/img/icon-dots.svg" alt="icon" @click="handleMoreClick">
        </div>

        <!-- 对话框 -->
        <div class="dialog-box" ref="dialogBox">
          <el-icon class="is-loading" color="#292929" size="16" v-if="isFetchingHistories" style="margin: 10px auto">
            <Loading/>
          </el-icon>
          <!-- 历史 -->
          <message-bubble
            :message="history"
            v-for="(history) in histories"
            :key="history.id"
          />
          <!-- 新对话 -->
          <message-bubble
            :message="message"
            v-for="(message, key) in messages"
            :key="key"
            @rendered="handleMessageRendered"
          />
          <!-- waiting bubble -->
          <message-bubble
            v-if="talkStatus === TALK_STATUS.WAITING"
            :message="{role: ROLE.ASSISTANT, cardType: CARD_TYPE.WAITING}"
          />
          <!-- error bubble -->
          <message-bubble
            v-if="talkStatus === TALK_STATUS.ERROR"
            :message="{role: ROLE.ASSISTANT, cardType: CARD_TYPE.ERROR}"
            @retry="handleRetry"
          />
        </div>

        <!-- back to bottom -->
        <transition name="fade" mode="out-in">
          <div class="back" v-if="isShowBtnScrollToBottom">
            <img class="arrow-down" src="@/assets/img/Arrow%20down.svg" alt="down" @click="scrollToBottomSmooth">
          </div>
        </transition>

        <!-- 提示词 -->
        <transition name="fade" mode="out-in">
          <div class="ask-me-about" v-if="tipSections.length">
            <div class="title">
              Ask me about
            </div>
            <div class="lines">
              <scroll-list :tips="tips.tipList" v-for="tips in tipSections" @tip-select="handleTipSelect"/>
            </div>
          </div>
        </transition>

        <!-- 用户输入框 -->
        <div class="user-input">
          <div class="input-container">
            <input
              ref="inputRef"
              type="text"
              placeholder="Ask BNTO AI or SEARCH"
              v-model.trim="text"
              @keydown.enter="handleMessageSend"
              @focus="scrollToTop"
              @blur="scrollToTop"
              enterkeyhint="send"
            >
            <div class="btn-container">
              <img
                src="@/assets/img/icon-send.svg"
                alt="send"
                v-if="text && talkStatus === TALK_STATUS.LEISURE"
                @click="handleMessageSend"
              >
              <img
                src="@/assets/img/icon-send-white.svg"
                class="btn-disabled"
                alt="loading"
                v-if="talkStatus === TALK_STATUS.WAITING || talkStatus === TALK_STATUS.AI_TALKING || talkStatus === TALK_STATUS.ERROR"
              >
            </div>
          </div>
          <div class="poweredBy">Powered By AlMA.ai</div>
        </div>
      </div>
    </Drawer>
  </div>
  <div class="drawer-ai-desktop" v-else>
    <Drawer :open="open" :position="'right'" :z-index="3" @close="handleCloseAI">
      <div class="drawer">
        <div class="drawer-header">
          <img class="close" src="@/assets/img/icon-close-black.svg" alt="icon" @click="handleCloseAI">
          <div class="header-title">
            <img src="@/assets/img/icon-ai.svg" alt="icon">
            bnto ai
            <span class="beta">beta</span>
          </div>
          <img class="more" src="@/assets/img/icon-dots.svg" alt="icon" @click="handleMoreClick">
        </div>

        <!-- 对话框 -->
        <div class="dialog-box" ref="dialogBox">
          <el-icon class="is-loading" color="#292929" size="16" v-if="isFetchingHistories" style="margin: 10px auto">
            <Loading/>
          </el-icon>
          <!-- 历史 -->
          <message-bubble
            :message="history"
            v-for="(history) in histories"
            :key="history.id"
          />
          <!-- 新对话 -->
          <message-bubble
            :message="message"
            v-for="(message, key) in messages"
            :key="key"
            @rendered="handleMessageRendered"
          />
          <!-- waiting bubble -->
          <message-bubble
            v-if="talkStatus === TALK_STATUS.WAITING"
            :message="{role: ROLE.ASSISTANT, cardType: CARD_TYPE.WAITING}"
          />
          <!-- error bubble -->
          <message-bubble
            v-if="talkStatus === TALK_STATUS.ERROR"
            :message="{role: ROLE.ASSISTANT, cardType: CARD_TYPE.ERROR}"
            @retry="handleRetry"
          />
        </div>

        <!-- back to bottom -->
        <transition name="fade" mode="out-in">
          <div class="back" v-if="isShowBtnScrollToBottom">
            <img class="arrow-down" src="@/assets/img/Arrow%20down.svg" alt="down" @click="scrollToBottomSmooth">
          </div>
        </transition>

        <!-- 提示词 -->
        <transition name="fade" mode="out-in">
          <div class="ask-me-about" v-if="tipSections.length">
            <div class="title">
              Ask me about
            </div>
            <div class="lines">
              <scroll-list :tips="tips.tipList" v-for="tips in tipSections" @tip-select="handleTipSelect"/>
            </div>
          </div>
        </transition>

        <!-- 用户输入框 -->
        <div class="user-input">
          <div class="input-container">
            <input
              ref="inputRef"
              type="text"
              placeholder="Ask BNTO AI or SEARCH"
              v-model.trim="text"
              @keydown.enter="handleMessageSend"
            >
            <div class="btn-container">
              <img
                src="@/assets/img/icon-send.svg"
                alt="send"
                v-if="text && talkStatus === TALK_STATUS.LEISURE"
                @click="handleMessageSend"
              >
              <img
                src="@/assets/img/icon-send-white.svg"
                class="btn-disabled"
                alt="loading"
                v-if="talkStatus === TALK_STATUS.WAITING || talkStatus === TALK_STATUS.AI_TALKING || talkStatus === TALK_STATUS.ERROR"
              >
            </div>
          </div>
          <div class="poweredBy">Powered By AlMA.ai</div>
        </div>
      </div>
    </Drawer>
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/config";

.drawer-ai-mobile {
  .drawer {
    width: 100%;
    height: calc(100vh - $mobile-header-height - $mobile-safari-address-bar-height - 18px);
    background: url("@/assets/img/bg-ai.png") white top center no-repeat;
    background-size: cover;

    display: flex;
    flex-direction: column;

    .drawer-header {
      width: 100%;
      padding: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .close {
        width: 30px;
        height: 30px;
        padding: 5px;
        border: 1px solid $color-BNTO-beige-dark;
        cursor: pointer;
      }

      .header-title {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        /* Eyebrow/medium */
        font-family: "TWK Lausanne";
        font-size: 14px;
        font-style: normal;
        font-weight: 650;
        line-height: 18px; /* 128.571% */
        letter-spacing: -0.056px;
        text-transform: uppercase;
        position: relative;

        .beta {
          position: absolute;
          right: -21.87px;
          bottom: 15px;

          color: $color-BNTO-beige-dark;
          font-family: "TWK Lausanne";
          font-size: 10px;
          font-style: normal;
          font-weight: 300;
          line-height: 12px; /* 120% */
          text-transform: lowercase;
        }
      }

      .more {
        width: 30px;
        height: 30px;
        padding: 5px;
        cursor: pointer;
      }
    }

    .dialog-box {
      height: 100%;
      overflow-y: auto;
      padding-bottom: 200px;
      display: flex;
      flex-direction: column;
      gap: 16px;

      &::-webkit-scrollbar {
        width: 0;
      }
    }

    .back {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 0;

      .arrow-down {
        position: relative;
        z-index: 1;
        margin-top: -42px;
        width: 32px;
        height: 32px;
        padding: 7px 6px 5px 6px;

        border-radius: 50%;
        border: 1px solid $color-BNTO-beige-dark;
        background: #FFF;
        cursor: pointer;
      }
    }

    .ask-me-about {
      padding: 10px 16px;

      .title {
        font-family: "TWK Lausanne";
        font-size: 14px;
        font-style: normal;
        font-weight: 650;
        line-height: 18px; /* 128.571% */
      }

      .lines {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 10px;
      }
    }

    .user-input {
      padding: 6px 16px 30px 16px;

      .input-container {
        width: 100%;
        border: 1px solid $color-gray-300;
        background: white;
        padding: 7px 7px 7px 12px;
        display: flex;
        align-items: center;

        input {
          width: 100%;
          height: 28px;
          /* Text xs/Regular */
          font-family: "TWK Lausanne";
          font-size: 12px;
          font-style: normal;
          font-weight: 300;
          line-height: 18px; /* 150% */
        }

        .btn-container {
          display: flex;

          img {
            width: 28px;
            height: 28px;
            padding: 5px;

            box-shadow: 0 0 0 0.5px $color-BNTO-beige-dark;
            background: $color-BNTO-beige-light;

            cursor: pointer;
          }

          .btn-disabled {
            background-color: $color-gray-300;
            border: none;
            cursor: not-allowed;
          }
        }
      }

      .poweredBy {
        margin-top: 6px;
        color: $color-BNTO-beige-dark;
        text-align: right;
        font-family: "TWK Lausanne";
        font-size: 10px;
        font-style: normal;
        font-weight: 300;
        line-height: 12px; /* 120% */
      }
    }
  }
}

.drawer-ai-desktop {
  .drawer {
    width: 580px;
    height: calc(100vh - $desktop-header-height);
    background: url("@/assets/img/bg-ai.png") white top center no-repeat;
    background-size: cover;

    display: flex;
    flex-direction: column;

    .drawer-header {
      width: 100%;
      padding: 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .close {
        width: 40px;
        height: 40px;
        padding: 10px;
        border: 1px solid $color-BNTO-beige-dark;
        cursor: pointer;
      }

      .header-title {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 5px;
        /* Eyebrow/medium */
        font-family: "TWK Lausanne";
        font-size: 14px;
        font-style: normal;
        font-weight: 650;
        line-height: 18px; /* 128.571% */
        letter-spacing: -0.056px;
        text-transform: uppercase;
        position: relative;

        .beta {
          position: absolute;
          right: -21.87px;
          bottom: 15px;

          color: $color-BNTO-beige-dark;
          font-family: "TWK Lausanne";
          font-size: 10px;
          font-style: normal;
          font-weight: 300;
          line-height: 12px; /* 120% */
          text-transform: lowercase;
        }
      }

      .more {
        width: 40px;
        height: 40px;
        padding: 10px;
        cursor: pointer;
      }
    }

    .dialog-box {
      height: 100%;
      overflow-y: auto;
      padding-bottom: 200px;
      display: flex;
      flex-direction: column;
      gap: 16px;

      &::-webkit-scrollbar {
        width: 0;
      }
    }

    .back {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 0;

      .arrow-down {
        position: relative;
        z-index: 1;
        margin-top: -42px;
        width: 32px;
        height: 32px;
        padding: 7px 6px 5px 6px;

        border-radius: 50%;
        border: 1px solid $color-BNTO-beige-dark;
        background: #FFF;
        cursor: pointer;
      }
    }

    .ask-me-about {
      padding: 10px 16px;

      .title {
        font-family: "TWK Lausanne";
        font-size: 14px;
        font-style: normal;
        font-weight: 650;
        line-height: 18px; /* 128.571% */
      }

      .lines {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 10px;
      }
    }

    .user-input {
      padding: 6px 16px 30px 16px;

      .input-container {
        width: 100%;
        border: 1px solid $color-gray-300;
        background: white;
        padding: 7px 7px 7px 12px;
        display: flex;
        align-items: center;

        input {
          width: 100%;
          height: 28px;
          /* Text xs/Regular */
          font-family: "TWK Lausanne";
          font-size: 12px;
          font-style: normal;
          font-weight: 300;
          line-height: 18px; /* 150% */
        }

        .btn-container {
          display: flex;

          img {
            width: 28px;
            height: 28px;
            padding: 5px;

            box-shadow: 0 0 0 0.5px $color-BNTO-beige-dark;
            background: $color-BNTO-beige-light;

            cursor: pointer;
          }

          .btn-disabled {
            background-color: $color-gray-300;
            border: none;
            cursor: not-allowed;
          }
        }
      }

      .poweredBy {
        margin-top: 6px;
        color: $color-BNTO-beige-dark;
        text-align: right;
        font-family: "TWK Lausanne";
        font-size: 10px;
        font-style: normal;
        font-weight: 300;
        line-height: 12px; /* 120% */
      }
    }
  }
}
</style>
