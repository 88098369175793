<script setup>
import {useBaseStore} from "~/stores/base";
import Drawer from "~/components/Drawer.vue";
import ModuleCartItem from "~/components/DrawerCart/ModuleCartItem.vue";
import {updateCart} from "~/utils/common";
import {useCartStore} from "~/stores/cart";
import ModuleCartHeader from "~/components/DrawerCart/ModuleCartHeader.vue";
import ModuleCartBottom from "~/components/DrawerCart/ModuleCartBottom.vue";
import {BNTO_STATUS} from "~/utils/constants";
import ModulePaused from "~/components/DrawerCart/ModulePaused.vue";
import ModuleCancelled from "~/components/DrawerCart/ModuleCancelled.vue";
import ModuleNotSubscribed from "~/components/DrawerCart/ModuleNotSubscribed.vue";
import ModuleGuest from "~/components/DrawerCart/ModuleGuest.vue";
import ModuleUnitPurchase from "~/components/DrawerCart/ModuleUnitPurchase.vue";
import {PAGE_LABEL, smartLookTrackPageView} from "~/utils/smartlook";

const route = useRoute()
const router = useRouter()

/** data **/
const open = ref(false)
const isShowUnitPurchase = ref(false)
const unitPurchaseItem = ref({})

/** computed **/
const isMobile = computed(() => useBaseStore().getterIsMobile)
const isLogin = computed(() => useBaseStore().getterIsLogin)
const userInfo = computed(() => useBaseStore().getterUserInfo)
const topInfo = computed(() => useCartStore().getterTopInfo)
const cartItemList = computed(() => useCartStore().getterCartItemList)
const isShowBottom = computed(() =>
  isLogin.value &&
  userInfo.value.isMember &&
  cartItemList.value.length === userInfo.value.bntoUpperLimit &&
  topInfo.value.status !== BNTO_STATUS.REQUESTED &&
  topInfo.value.status !== BNTO_STATUS.PAUSED &&
  topInfo.value.status !== BNTO_STATUS.CANCELLED
)
const orderId = computed(() => useBaseStore().getterUserInfo.bntoOrderId)
const hash = computed(() => route.hash)

/** watch **/
watch(hash, () => {
  if (hash.value === "#cart") {
    updateCart()
    open.value = true
    useBaseStore().updatePageLabel(PAGE_LABEL.CART)
    smartLookTrackPageView()
  } else {
    open.value = false
    reset()
  }
})

/** methods **/
const reset = () => {
  isShowUnitPurchase.value = false
  unitPurchaseItem.value = {}
}
const handleCloseDrawerCart = () => {
  // 获取当前路径，但去掉 hash 部分
  router.replace({
    path: router.currentRoute.value.path,
    query: router.currentRoute.value.query, // 保留查询参数
    hash: '' // 移除 hash
  });
}
const handleRemove = async (e) => {
  cartItemList.value = cartItemList.value.filter(v => v.cartItemId !== e.cartItemId)
  await updateCart()
}
const handleBuy = async (e) => {
  isShowUnitPurchase.value = true;
  unitPurchaseItem.value = e.product
}
const handleUnitPurchaseClose = () => {
  isShowUnitPurchase.value = false;
  unitPurchaseItem.value = {}
}

/** lifecycle **/
onMounted(() => {
  if (hash.value === "#cart") {
    updateCart()
    open.value = true
  }
})
</script>

<template>
  <div class="drawer-cart-mobile" v-if="isMobile">
    <Drawer :open="open" :position="'right'" :z-index="3" @close="handleCloseDrawerCart">
      <!-- 购物车 -->
      <div class="cart">
        <module-cart-header/>
        <template v-if="isLogin">
          <template v-if="userInfo.isMember">
            <module-paused v-if="topInfo.status === BNTO_STATUS.PAUSED"/>
            <module-cancelled v-else-if="topInfo.status === BNTO_STATUS.CANCELLED"/>
            <div v-else class="cart-body" :style="{paddingBottom: isShowBottom ? '108px' : '15px'}">
              <!-- 有数据 -->
              <module-cart-item :order-id="orderId" :product="item" :bnto-status="topInfo.status" v-for="item in cartItemList" @remove="handleRemove" @buy="handleBuy"/>
              <!-- 空占位 -->
              <module-cart-item :order-id="''" :product="{}" :bnto-status="''" v-for="item in userInfo.bntoUpperLimit - cartItemList.length" @remove="() => {}" @buy="() => {}"/>
            </div>
          </template>
          <module-not-subscribed v-else/>
        </template>
        <module-guest v-else/>
        <module-cart-bottom v-if="isShowBottom"/>
      </div>
      <!-- 商品购买 -->
      <module-unit-purchase v-if="isShowUnitPurchase" :product="unitPurchaseItem" :order-id="orderId" @back="handleUnitPurchaseClose"/>
    </Drawer>
  </div>
  <div class="drawer-cart-desktop" v-else>
    <Drawer :open="open" :position="'right'" :z-index="3" @close="handleCloseDrawerCart">
      <!-- 购物车 -->
      <div class="cart">
        <module-cart-header/>
        <template v-if="isLogin">
          <template v-if="userInfo.isMember">
            <module-paused v-if="topInfo.status === BNTO_STATUS.PAUSED"/>
            <module-cancelled v-else-if="topInfo.status === BNTO_STATUS.CANCELLED"/>
            <div v-else class="cart-body" :style="{paddingBottom: isShowBottom ? '108px' : '15px'}">
              <!-- 有数据 -->
              <module-cart-item :order-id="orderId" :product="item" :bnto-status="topInfo.status" v-for="item in cartItemList" @remove="handleRemove" @buy="handleBuy"/>
              <!-- 空占位 -->
              <module-cart-item :order-id="''" :product="{}" :bnto-status="''" v-for="item in userInfo.bntoUpperLimit - cartItemList.length" @remove="() => {}" @buy="() => {}"/>
            </div>
          </template>
          <module-not-subscribed v-else/>
        </template>
        <module-guest v-else/>
        <module-cart-bottom v-if="isShowBottom"/>
      </div>
      <!-- 商品购买 -->
      <module-unit-purchase v-if="isShowUnitPurchase" :product="unitPurchaseItem" :order-id="orderId" @back="handleUnitPurchaseClose"/>
    </Drawer>
  </div>
</template>

<style scoped lang="scss">
@import "src/assets/config";

.drawer-cart-mobile {
  .cart {
    width: 375px;
    height: calc(100vh - $mobile-header-height);
    overflow-y: auto;
    padding-bottom: $mobile-safari-address-bar-height;
    position: relative;

    .cart-body {
      padding-top: 15px;
      padding-bottom: 15px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 15px;
    }
  }
}

.drawer-cart-desktop {
  .cart {
    width: 375px;
    height: calc(100vh - $desktop-header-height);
    overflow-y: auto;
    position: relative;

    .cart-body {
      padding-top: 15px;
      padding-bottom: 15px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 15px;
    }
  }
}
</style>
